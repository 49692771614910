import './ComingSoon.css';

function ComingSoon() {
    return (
        <div className="ComingSoon">
            <p>Coming soon...</p>
        </div>
    )
}

export default ComingSoon;
