import React from 'react';
import logo from './logo.svg';
import './App.css';
import ComingSoon from './ComingSoon';

function App() {
  return (
    <div className="App">
      <header>
        <ComingSoon />
      </header>
    </div>
  );
}

export default App;
